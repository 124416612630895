import { ChangeEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { SubprojectContext, useProjectConfig } from '../../../ContextProviders/AppContext';
import { useConcreteProject } from '../../../ContextProviders/ProjectContext';
import { IconButton } from '../../../Buttons/Buttons';
import {
  faClose,
  faEnvelope,
  faEnvelopes,
  faFileCsv,
  faInfo,
  faPlus,
  faSearch,
} from '@fortawesome/pro-solid-svg-icons';
import { useLocalization } from '../../../ContextProviders/LocalizationContext';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Popover,
  PopoverBody,
  UncontrolledDropdown,
} from 'reactstrap';
import { InvitationPayload, InvitationStatus, StaffRoleTire, UserRole } from '@eir/core';
import { toast } from 'react-toastify';
import SortableTable from './SortableTable';
import { useCollection } from '../../../../Hooks';
import { CMSUser, WithID } from '../../../../Types';
import { LoadingSVG } from '../../Loading/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { detectDelimiter } from '../../../../util/DataHelpers';
import confirm from 'reactstrap-confirm';
import pThrottle from 'p-throttle';
import './MultiStaffAccount.scss';
import { useAuth } from '../../../ContextProviders/Auth';

// Define a type for storing valid email-role pairs
const throttle = pThrottle({
  limit: 1,
  interval: 500,
}); // Adjust the concurrency limit as needed
const dummyRole: { role: UserRole; roleTire: StaffRoleTire & WithID } = {
  role: UserRole.STAFF,
  roleTire: { name: '', categoryPermissions: [], fId: '', hasWorkOrderAccess: false, isSubprojectAdmin: false },
};

const MultiStaffAccount = () => {
  const activeSubProject = useContext(SubprojectContext);
  const project = useConcreteProject();
  const localization = useLocalization();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { docs: CMSUsers, error: arError, loading: cmsLoading } = useCollection<CMSUser>('/cmsUser', {});
  const {
    docs: staffRoleTires,
    loading: roleLoading,
    error: roleError,
  } = useCollection<StaffRoleTire & WithID>('/roles', {});
  const auth = useAuth();

  const [validPairs, setValidPairs] = useState<InvitationPayload[]>([]);
  const [email, setEmail] = useState<string>('');
  const [role, setRole] = useState<{ role: UserRole; roleTire: (StaffRoleTire & WithID) | null }>();
  const [modalOpen, setModalOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [baseUrl, setBaseUrl] = useState<string>();
  const [selectedRows, setSelectedRows] = useState<CMSUser[]>([]);
  const [filteredData, setFilteredData] = useState<CMSUser[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [warnings, setWarnings] = useState({ duplicatedEmails: 0, invalidEmails: 0, invalidRoles: 0 });
  const [filteredCMSUsers, setFilteredCMSUsers] = useState<CMSUser[]>([]);
  const config = useProjectConfig();
  const maxAllowedUsers = config.doc.allowedUsers ?? 0; // TODO: in the UI -1 is considered as infinite number of users. Fix related places if needs any change.
  const [popover, setPopover] = useState(false);

  const toggleModal = () => {
    setModalOpen((prevState) => !prevState);
    setEmail('');
    setRole(undefined);
  };
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const isValidEmail = (email: string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const isValidRole = useCallback(
    ({ role, roleTire }: { role: UserRole; roleTire: (StaffRoleTire & WithID) | null }): boolean => {
      if (role === UserRole.ADMIN) return true;

      if (staffRoleTires.length < 1 || roleError) {
        toast.warn(localization.strings.project.notLoaded);
        return false;
      }
      if (!roleTire) return false;
      return staffRoleTires.some((rt) => rt.fId === roleTire.fId || rt.name === roleTire.name);
    },
    [localization.strings.project.notLoaded, roleError, staffRoleTires],
  );

  useEffect(() => {
    if (project && project.firebase && project.firebase.project) {
      setBaseUrl(`https://europe-west3-${project.firebase.project}.cloudfunctions.net/MultiAccountApi`);
      // Warmup the instance
      fetch(`https://europe-west3-${project.firebase.project}.cloudfunctions.net/MultiAccountApi/warmer`, {
        method: 'GET',
      })
        .then(() => {
          /** */
        })
        .catch(() => {
          /** */
        });
    }
  }, [project]);

  const handleFileUpload = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSelectedRows([]);
      const file = event.target.files ? event.target.files[0] : null;
      if (!file) {
        return;
      }
      setError(false); // Reset error state
      setWarnings({ duplicatedEmails: 0, invalidEmails: 0, invalidRoles: 0 }); // Reset warnings state

      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target?.result;
        if (!content || typeof content !== 'string') {
          setError(true);
          toast.error(localization.strings.settings.import.csvFormatError);
          return;
        }
        const delimiter = detectDelimiter(content);
        const rows = content?.toString().split('\n') || [];
        const processedEmails = new Set<string>();
        const emailRolePairs: InvitationPayload[] = [];

        if (maxAllowedUsers !== -1 && rows.length >= maxAllowedUsers - filteredCMSUsers.length) {
          setError(true);
          toast.error(localization.strings.settings.import.exceedingMaxUsers);
          return;
        }
        for (const row of rows) {
          if (row.trim() === '' || row.trim() === delimiter) continue;
          const [email, role] = row.split(delimiter).map((item) => item.trim());
          if (!email || !role) {
            setError(true);
            toast.error(localization.strings.settings.import.csvFormatError);
            return;
          }

          let hasError = false;

          if (!isValidEmail(email)) {
            setWarnings((prev) => ({ ...prev, invalidEmails: prev.invalidEmails + 1 }));
            hasError = true;
          }
          const _role: {
            role: UserRole;
            roleTire: (StaffRoleTire & WithID) | null;
          } =
            role === UserRole.ADMIN
              ? { role: UserRole.ADMIN, roleTire: null }
              : { role: UserRole.STAFF, roleTire: { ...dummyRole.roleTire, name: role } };
          if (!isValidRole(_role)) {
            setWarnings((prev) => ({ ...prev, invalidRoles: prev.invalidRoles + 1 }));
            hasError = true;
          }
          if (processedEmails.has(email)) {
            setWarnings((prev) => ({ ...prev, duplicatedEmails: prev.duplicatedEmails + 1 }));
            hasError = true;
          }

          if (hasError) continue;

          processedEmails.add(email);
          emailRolePairs.push({
            email,
            role: role === UserRole.ADMIN ? UserRole.ADMIN : UserRole.STAFF,
            roleTire: role === UserRole.ADMIN ? null : staffRoleTires.find((r) => r.name === role)?.fId,
            projectId: activeSubProject && activeSubProject.id ? activeSubProject.id : project.id,
            status: InvitationStatus.PENDING,
            invitationUrl: `${window.location.origin}/invitation/${btoa(email)}`,
            sender: project.sender,
          } as InvitationPayload);
        }

        if (!error && emailRolePairs.length) {
          setValidPairs(emailRolePairs);
          event.target.value = ''; // Reset input value
        }
      };
      reader.readAsText(file);
    },
    // eslint-disable-next-line
    [
      activeSubProject,
      error,
      isValidRole,
      localization.strings.settings.import.csvFormatError,
      project.id,
      project.sender,
      staffRoleTires,
      filteredCMSUsers.length,
      maxAllowedUsers,
    ],
  );

  useEffect(() => {
    Object.keys(warnings).forEach((warningKey) => {
      if (warnings[warningKey] > 0) {
        toast.warn(`${warnings[warningKey]} ${warningKey}`, { position: 'top-right' });
      }
    });
  }, [warnings]);

  const inviteUser = async () => {
    if (!email || !role || !isValidEmail(email) || !isValidRole(role)) {
      toast.warn(localization.strings.settings.multiStaffAccount.errorEmailRole);
      return;
    }
    if (!baseUrl) {
      toast.warn(localization.strings.project.notLoaded);
      return;
    }
    try {
      setLoading(true);
      const url = `${baseUrl}/invite-user`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          projectId: activeSubProject && activeSubProject.id ? activeSubProject.id : project.id,
          status: InvitationStatus.PENDING,
          role: role.role,
          roleTire:
            (role.role === UserRole.STAFF || role.role === UserRole.SUBPROJECT_ADMIN) && role.roleTire
              ? role.roleTire.fId
              : null,
          invitationUrl: `${window.location.origin}/invitation/${btoa(email)}`,
          sender: project.sender,
        } as InvitationPayload),
      });
      if ((response && response.status === 200) || response.status === 201) {
        toast.success(localization.strings.settings.multiStaffAccount.invitationSuccess);
      }
    } catch (e) {
      toast.error(localization.strings.settings.emailError);
    } finally {
      setLoading(false);
      toggleModal();
      setSelectedRows([]);
    }
  };

  const inviteAll = async () => {
    if (validPairs.length < 1) {
      toast.warn(localization.strings.settings.multiStaffAccount.errorEmailRole);
      return;
    }
    if (!baseUrl) {
      toast.warn(localization.strings.project.notLoaded);
      return;
    }
    try {
      setLoading(true);
      const url = `${baseUrl}/invite-user`;
      const throttled = throttle(async (pair: InvitationPayload) => {
        return await fetch(url, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            email: pair.email,
            projectId: pair.projectId,
            status: InvitationStatus.PENDING,
            role: pair.role,
            roleTire: pair.roleTire,
            invitationUrl: pair.invitationUrl,
            sender: project.sender,
          } as InvitationPayload),
        });
      });

      const responses = await Promise.all(validPairs.map(async (pair) => await throttled(pair)));

      const successCount = responses.filter((r) => r.status === 200 || r.status === 201).length;
      const failCount = responses.filter((r) => r.status !== 200 && r.status !== 201 && r.status !== 409).length;
      const duplicatesCount = responses.filter((r) => r.status === 409).length;
      if (successCount > 0)
        toast.success(
          `${successCount} ${
            successCount === 1
              ? localization.strings.settings.multiStaffAccount.sendInvitationSuccess
              : localization.strings.settings.multiStaffAccount.sendInvitationsSuccess
          }`,
        );
      if (failCount > 0)
        toast.error(
          `${failCount} ${
            failCount === 1
              ? localization.strings.settings.multiStaffAccount.sendInvitationFailure
              : localization.strings.settings.multiStaffAccount.sendInvitationsFailure
          }`,
        );
      if (duplicatesCount > 0)
        toast.warn(
          `${duplicatesCount} ${
            duplicatesCount === 1
              ? localization.strings.settings.multiStaffAccount.sendInvitationDuplicate
              : localization.strings.settings.multiStaffAccount.sendInvitationsDuplicate
          }`,
        );
    } catch (e) {
      toast.error(localization.strings.settings.emailError);
    } finally {
      setLoading(false);
      setValidPairs([]);
      setSelectedRows([]);
    }
  };

  const resendInvitation = async (user?: CMSUser) => {
    if (!baseUrl) {
      toast.warn(localization.strings.project.notLoaded);
      return;
    }
    try {
      setLoading(true);
      const url = `${baseUrl}/re-invite-user`;
      const throttled = throttle(async (user: CMSUser) => {
        return await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: user.email,
            projectId: user.projectId,
            status: user.status,
            role: user.role,
            invitationUrl: `${window.location.origin}/invitation/${btoa(user.email)}`,
            roleTire: user.roleTire,
            sender: project.sender,
          } as InvitationPayload),
        });
      });
      const pendingUsers = user ? [user] : selectedRows.filter((row) => row.status === InvitationStatus.PENDING);
      const responses = await Promise.all(pendingUsers.map(async (cmsUser) => await throttled(cmsUser)));

      const successCount = responses.filter((r) => r.status === 200).length;
      const failCount = responses.filter((r) => r.status !== 200).length;

      if (successCount > 0)
        toast.success(
          `${successCount} ${
            successCount === 1
              ? localization.strings.settings.multiStaffAccount.sendInvitationSuccess
              : localization.strings.settings.multiStaffAccount.sendInvitationsSuccess
          }`,
        );
      if (failCount > 0)
        toast.error(
          `${failCount} ${
            failCount === 1
              ? localization.strings.settings.multiStaffAccount.sendInvitationFailure
              : localization.strings.settings.multiStaffAccount.sendInvitationsFailure
          }`,
        );
    } catch (e) {
      console.error('resendInvitation failed:', e);
      toast.error(localization.strings.settings.emailError);
    } finally {
      setLoading(false);
      setSelectedRows([]);
    }
  };

  const deleteUser = async (user?: CMSUser) => {
    const listEmails = user === undefined ? selectedRows.map((row) => row.email).join(`\n`) : user.email;
    const result = await confirm({
      title: `${
        user !== undefined || selectedRows.length === 1
          ? localization.strings.settings.deleteAccount
          : localization.strings.settings.multiStaffAccount.deleteAccounts
      }`,
      message: `${
        user !== undefined || selectedRows.length === 1
          ? `${localization.strings.settings.confirmDeleteAccount}:\n${listEmails}`
          : `${localization.strings.settings.multiStaffAccount.confirmDeleteAccounts}:\n\n${listEmails}`
      }`,
      confirmText: localization.strings.global.confirm,
      confirmColor: 'primary',
      cancelText: localization.strings.global.cancel,
      cancelColor: 'link text-danger',
    });
    if (!result) return;

    if (!baseUrl) {
      toast.warn(localization.strings.project.notLoaded);
      return;
    }
    setLoading(true);
    try {
      const users = user ? [user] : selectedRows;
      const throttled = throttle(async (url: string) => {
        return await fetch(url, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        });
      });
      const responses = await Promise.all(
        users.map(async (s) => await throttled(`${baseUrl}/delete-user/${btoa(s.email)}`)),
      );

      const successCount = responses.filter((r) => r.status === 200).length;
      const failCount = responses.filter((r) => r.status !== 200).length;

      if (successCount > 0)
        toast.success(
          `${successCount} ${
            successCount === 1
              ? localization.strings.settings.multiStaffAccount.deleteAccountSuccess
              : localization.strings.settings.multiStaffAccount.deleteAccountsSuccess
          }`,
        );
      if (failCount > 0)
        toast.error(
          `${failCount} ${
            failCount === 1
              ? localization.strings.settings.multiStaffAccount.deleteAccountFailure
              : localization.strings.settings.multiStaffAccount.deleteAccountsFailure
          }`,
        );
    } catch (e) {
      console.error('resendInvitation failed:', e);
      toast.error(localization.strings.settings.emailError);
    } finally {
      setLoading(false);
      setSelectedRows([]);
    }
  };

  const changeRoleMultipleUsers = (role) => {
    const _role = staffRoleTires.find((rn) => rn.fId === role.fId);
    selectedRows.map(async (user) => {
      user.role = role.name;
      await changeRole({
        ...user,
        role: role.isSubprojectAdmin ? UserRole.SUBPROJECT_ADMIN : UserRole.STAFF,
        roleTire: role.fId,
        categoryPermissions: _role ? _role.categoryPermissions : [],
      });
    });
  };

  const changeRole = async (user: CMSUser) => {
    if (!baseUrl) {
      toast.warn(localization.strings.project.notLoaded);
      return;
    }
    setLoading(true);
    try {
      const url = `${baseUrl}/update-user-role`;
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });
      if (response.status === 200) {
        toast.success(localization.strings.global.ok);
      }
    } catch (e) {
      console.error('updating role failed:', e);
      toast.error(localization.strings.settings.emailError);
    } finally {
      setLoading(false);
    }
  };

  const downloadCSV = useCallback(() => {
    const projId = activeSubProject && activeSubProject.id ? activeSubProject.id : project.id;
    // Prepare CSV data
    const csvContent = selectedRows
      .map(
        (item) =>
          `${item.email},${
            item.role === UserRole.ADMIN ? item.role : staffRoleTires.find((r) => r.fId === item.roleTire)?.name
          }`,
      )
      .join('\n');

    // Create a Blob from the CSV String
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create a link element for the download
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `${projId}_emails_roles.csv`);

    // Append the link to the body (required for Firefox)
    document.body.appendChild(link);

    // Trigger the download by simulating a click
    link.click();

    // Clean up by removing the link and revoking the Blob URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    setSelectedRows([]);
  }, [activeSubProject, project.id, selectedRows, staffRoleTires]);

  useEffect(() => {
    if (!searchQuery.trim()) {
      setFilteredData(filteredCMSUsers);
    } else {
      const filtered = filteredCMSUsers.filter(
        (d) =>
          d.displayName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          d.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
          (d.roleTire && d.roleTire.toLowerCase().includes(searchQuery.toLowerCase())),
      );
      setFilteredData(filtered);
    }
  }, [searchQuery, filteredCMSUsers]);

  useEffect(() => {
    if (activeSubProject && activeSubProject.id) {
      setFilteredCMSUsers(
        CMSUsers.filter((u) => u.projectId === activeSubProject.id && u.role !== UserRole.SUPER_ADMIN),
      );
    } else {
      setFilteredCMSUsers(
        CMSUsers.filter((u) =>
          u.projectId !== undefined
            ? u.projectId === project.id && u.role !== UserRole.SUPER_ADMIN
            : u.role !== UserRole.SUPER_ADMIN,
        ),
      );
    }
  }, [CMSUsers, activeSubProject, project.id]);

  useEffect(() => {
    setSelectedRows([]);
  }, [filteredData]);

  type RoleNamesType = {
    name: string;
    isSubprojectAdmin: boolean;
    index: number;
  }[];

  const generateDropDownItems = useCallback(() => {
    const roleNames: RoleNamesType = [];
    if (!auth.isSubprojectAdmin) {
      roleNames.push({ name: `${UserRole.ADMIN}`, index: 0, isSubprojectAdmin: false });
    }
    staffRoleTires.map((role, i) =>
      roleNames.push({ name: role.name, index: i + 1, isSubprojectAdmin: role.isSubprojectAdmin }),
    );

    return roleNames.map((role) => (
      <DropdownItem
        key={role.index}
        onClick={() => {
          if (role.index === 0) {
            setRole({ role: UserRole.ADMIN, roleTire: null });
          } else {
            setRole({
              role: role.isSubprojectAdmin ? UserRole.SUBPROJECT_ADMIN : UserRole.STAFF,
              roleTire: staffRoleTires[role.index - 1],
            });
          }
        }}
      >
        {role.name}
      </DropdownItem>
    ));
  }, [staffRoleTires, auth.isSubprojectAdmin]);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div>
          <IconButton
            disabled={loading || (maxAllowedUsers !== -1 && filteredCMSUsers.length >= maxAllowedUsers)}
            isLoading={loading}
            icon={faFileCsv}
            theme="dark"
            text={localization.strings.settings.import.csvImport}
            onClick={() => inputFileRef.current?.click()}
            tooltip={localization.strings.settings.import.csvImport}
          ></IconButton>
          <input type="file" accept=".csv" onChange={handleFileUpload} ref={inputFileRef} style={{ display: 'none' }} />
        </div>
        <div>
          <IconButton
            disabled={loading || (maxAllowedUsers !== -1 && filteredCMSUsers.length >= maxAllowedUsers)}
            isLoading={loading}
            icon={faPlus}
            theme="dark"
            text={localization.strings.global.add}
            onClick={() => setModalOpen(true)}
            tooltip={localization.strings.settings.multiStaffAccount.addAccount}
          ></IconButton>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <p style={{ margin: '1rem 0' }}>
            {localization.strings.settings.subprojects.sorting.allowedUsers}:{filteredCMSUsers.length} /{' '}
            {maxAllowedUsers === -1 ? <span style={{ fontSize: 22 }}>∞</span> : maxAllowedUsers}
          </p>

          <IconButton
            icon={faInfo}
            id="Popover1"
            type="button"
            onClick={() => setPopover((prev) => !prev)}
            theme="dark"
            style={{ borderRadius: '50%', width: '45px', height: '45px', marginLeft: '1rem' }}
          />
          <Popover isOpen={popover} flip target="Popover1" toggle={() => setPopover((prev) => !prev)}>
            <PopoverBody>{localization.strings.settings.subprojects.contactInfosynk}</PopoverBody>
          </Popover>
        </div>
      </div>
      {validPairs.length > 0 && (
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1rem', alignItems: 'center' }}>
          <p style={{ margin: 0 }}>
            <strong>{validPairs.length}&nbsp;</strong>
            {localization.strings.settings.multiStaffAccount.importedMailsCount}
          </p>
          <IconButton
            disabled={loading}
            isLoading={loading}
            theme="dark"
            icon={faEnvelopes}
            text={localization.strings.settings.multiStaffAccount.inviteAll}
            onClick={() => inviteAll()}
            tooltip="Invite all the emails imported from CSV file"
          />
        </div>
      )}
      {selectedRows.length > 0 && (
        <>
          <br />
          <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
            {selectedRows.some((s) => s.status === InvitationStatus.PENDING) && (
              <IconButton
                disabled={loading}
                isLoading={loading}
                color="info"
                icon={faEnvelopes}
                text={localization.strings.settings.multiStaffAccount.reinvite}
                onClick={() => resendInvitation()}
                tooltip={localization.strings.settings.multiStaffAccount.reinviteTooltip}
              />
            )}
            <IconButton
              disabled={loading}
              isLoading={loading}
              theme="dark"
              icon={faFileCsv}
              text={localization.strings.settings.multiStaffAccount.exportCSV}
              onClick={() => downloadCSV()}
              tooltip={localization.strings.settings.multiStaffAccount.exportCSVTooltip}
            />
            <UncontrolledDropdown>
              <DropdownToggle color="primary">
                {localization.strings.settings.multiStaffAccount.editRole}
              </DropdownToggle>
              <DropdownMenu>
                {!auth.isSubprojectAdmin && (
                  <DropdownItem
                    style={{ margin: 0 }}
                    key={0}
                    onClick={() => changeRoleMultipleUsers({ name: `${UserRole.ADMIN}`, fId: '0' })}
                  >
                    {UserRole.ADMIN}
                  </DropdownItem>
                )}
                {staffRoleTires.map((role) => (
                  <DropdownItem style={{ margin: 0 }} key={role.fId} onClick={() => changeRoleMultipleUsers(role)}>
                    {role.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
            <IconButton
              disabled={loading || auth.isSubprojectAdmin}
              isLoading={loading}
              color="danger"
              icon={faEnvelopes}
              text={localization.strings.settings.multiStaffAccount.delete}
              onClick={() => deleteUser()}
              tooltip={localization.strings.settings.multiStaffAccount.deleteTooltip}
            />
          </div>
        </>
      )}
      <br />
      {cmsLoading || roleLoading ? (
        <LoadingSVG />
      ) : arError ? (
        <div>
          <p>Error loading user data!</p>
        </div>
      ) : (
        <>
          <InputGroup className="mx-1 mb-2 w-25" size="sm">
            <Input
              className="form-control-sm"
              value={searchQuery}
              placeholder={localization.strings.global.search}
              onChange={(e) => setSearchQuery(e.target.value)}
              bsSize="lg"
              style={{ border: 'none', borderBottom: '2px solid #ccc', borderRadius: 0 }}
            />

            <InputGroupText style={{ backgroundColor: 'transparent', border: 'none' }}>
              <FontAwesomeIcon
                icon={searchQuery.trim() ? faClose : faSearch}
                onClick={() => searchQuery.trim() && setSearchQuery('')}
              />
            </InputGroupText>
          </InputGroup>
          <SortableTable
            data={filteredData}
            setData={setFilteredData}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            resendInvitation={resendInvitation}
            deleteUser={deleteUser}
            loading={loading}
            changeRole={changeRole}
            roleTires={staffRoleTires}
          />
        </>
      )}

      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{localization.strings.settings.multiStaffAccount.inviteOne}</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="exampleEmail">{localization.strings.auth.emailTitle}</Label>
              <Input
                id="exampleEmail"
                name={localization.strings.auth.emailTitle}
                placeholder={localization.strings.auth.emailPlaceholder2}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="roleDropDown">{localization.strings.settings.accountRole}</Label>
              <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} direction={'down'} id="roleDropDown">
                <DropdownToggle caret outline color="primary">
                  {role ? (role.roleTire ? role.roleTire.name : role.role) : localization.strings.settings.accountRole}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => setRole(undefined)}>{localization.strings.global.clear}</DropdownItem>
                  <DropdownItem divider />
                  {generateDropDownItems()}
                </DropdownMenu>
              </Dropdown>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Button color="secondary" onClick={toggleModal} disabled={loading}>
            {localization.strings.global.cancel}
          </Button>
          <IconButton
            disabled={loading}
            isLoading={loading}
            icon={faEnvelope}
            theme="dark"
            text={localization.strings.auth.invite}
            onClick={() => inviteUser()}
          ></IconButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default MultiStaffAccount;
